export const projectdata = [
    {
        id: 1,
        img: './assets/img/9.jpg',
        title: 'House Cleaning',
        ex: 'Excellent service',
        link: '/houseservice',
    },
    {
        id: 2,
        img: './assets/img/afro.png',
        title: 'Corporate Cleaning',
        ex: 'Excellent service',
        link: '/corporate',
    },
    {
        id: 3,
        img: './assets/img/8.jpg',
        title: 'BeSpoke Services',
        ex: 'Excellent service',
        link: '/bespoke',
    },
    {
        id: 4,
        img: './assets/img/7.png',
        title: 'Fumigation Services',
        ex: 'Excellent service',
        link: '/fumigation',
    },
    {
        id: 2,
        img: './assets/img/clean2.jpg',
        title: 'Post Construction Cleaning',
        ex: 'Excellent service',
        link: '/construction',
    },
    {
        id: 2,
        img: './assets/img/window.jpg',
        title: 'Window Cleaning',
        ex: 'Excellent service',
        link: '/window',
    },
    {
        id: 2,
        img: './assets/img/party.jpg',
        title: 'After Party Cleaning',
        ex: 'Excellent service',
        link: '/afterparty',
    },
    {
        id: 2,
        img: './assets/img/toilet.jpg',
        title: 'Toilet Cleaning',
        ex: 'Excellent service',
        link: '/toilet',
    },
    {
        id: 2,
        img: './assets/img/toilet.jpg',
        title: 'Pre-Move-In Cleaning',
        ex: 'Excellent service',
        link: '/premovein',
    },
    {
        id: 2,
        img: './assets/img/toilet.jpg',
        title: 'Kitchen Cleaning',
        ex: 'Excellent service',
        link: '/kitchen',
    },
    {
        id: 2,
       
        title: 'Retainership',
        ex: 'Excellent service',
        link: '/kitchen',
    },
]